import Invoice from "./Invoice/Invoice";

function App() {
  return (
    <div>
      <Invoice />
    </div>
  );
}

export default App;
